.container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 80px;
    height: auto;
    width: 100%;
    margin: 50px 0px;
}

.introSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    height: auto;
    width: auto;
}

.introSection h1, 
.introSection h3 {
    margin: 0px;
}

.pictureSection {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 25%;
}

.pictureSection img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.socialLinks {
    margin: 20px 0px!important;
}

@media screen and (max-width: 875px){
    .container{
        flex-direction: column-reverse;
        gap: 20px;
    }

    .pictureSection {
        width: 75%;
    }

    .introSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-align: center;
    }

}


