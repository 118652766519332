/* GLOBAL STYLES (For fonts) */

body {
    font-family: 'Arial', sans-serif;
    background-color: #181818;
    color: White;
}

hr {
    margin: 20px 0px;
    border: none;
    border-bottom: 1px solid #515151;
}

li::marker{
    color: #0077b6;
}

span {
    color: #0077b6;
    font-weight: 700;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 1.5rem;
    color: #FCA311;
}

h3 {
    font-size: 1.25rem;
}

p, li, em, label, input, textarea, a{
    font-size: 1.15rem;
    font-family: 'Arial', sans-serif;
}

p {
    margin: 0px;
}

@media screen and (max-width: 875px){
    h1 {
        font-size: 2rem;
    }    
}

@media screen and (max-width: 425px){
    h1 {
        font-size: 1.5rem;
    }    

    h3 {
        font-size: 1.2rem;
    }
}
