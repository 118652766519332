.sectionTitle{
    height: 50px;
}

.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    height: 40px;
    width: 33%;

    border-radius: 10px;
    background: linear-gradient(#313131, #313131) padding-box,
              linear-gradient(to right, #0077b6, #FCA311) border-box;
    border: 4px solid transparent;
}

.card a {
    color: white;
    text-decoration: none;
}

.card a:hover {
    color: #FCA311;
}

.middle{
    margin: 0px 20px;
}

.icon {
    height: 40px;
    width: 40px;
    padding-right: 20px;
}

/*Contact Form*/

.contactFormContainer {
    max-width: 100%;
    margin: 20px auto;
    background-color: #515151;
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.266) 2px 2px 5px;
}

.contactForm{
    display: flex;
    flex-direction: column;
}

.contactForm sub {
    vertical-align: top;
    font-size: smaller;
    color: red;
  }

.contactForm label{
    color: #0077b6;
    font-weight: bold;
    text-shadow: rgba(0, 0, 0, 0.266) 1px 1px 1px;
}

.contactForm input, 
.contactForm textarea{
    background: none;
    border: 2px solid white;
    border-radius: 10px;
    margin: 10px 0px;
    margin-bottom: 30px;
    padding: 20px;
    height: 20px;
    max-width: 100%;
    background-color: white;
}

.contactForm textarea {
    height: 60px;
}

.contactForm input:hover, 
.contactForm textarea:hover{
    background: none;
    border: 2px solid #FCA311;
    border-radius: 10px;
    background-color: white;
}

.contactForm input:focus, 
.contactForm textarea:focus{
    outline: none;
    background: none;
    border: 2px solid #0077b6;
    border-radius: 10px;
    color: black;
    background-color: white;
}

.contactForm input:valid, 
.contactForm textarea:valid{
    border-radius: 10px;
    background: linear-gradient(white, white) padding-box,
              linear-gradient(to right, #0077b6, #FCA311) border-box;
    border: 2px solid transparent;
}

.contactForm button {
    background: none;
    border: 2px solid #0077b6;
    color: #0077b6;
    padding: 15px 50px;
    border-radius: 10px;
    font-size: 1rem;
    width: 15%;
    box-shadow: rgba(0, 0, 0, 0.266) 1px 1px 2px;
}

.contactForm button:hover {
    cursor: pointer;
    background: #FCA311;
    border: 2px solid #FCA311;
    color: white;
}

.contactForm button:active {
    background: #905d0a;
    border: 2px solid #905d0a;
}

.status {
    padding: 10px 0px;
    color: #333; /* Default */
    font-size: 1rem;
}
.status.success {
    color: green;
}
.status.error {
    color: red;
}

  
  @media screen and (max-width: 900px){
    .container{
        flex-direction: column;
        gap: 20px;
    }

    .card{
        height: 100%;
        width: 90%;
    }

    .middle{
        margin: 0px;
    }

    .contactForm button {
        width: 100%;
    }
}