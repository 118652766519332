.downloadButton {
    background: none;
    border: 2px solid #0077b6;
    color: #0077b6;
    padding: 15px 50px;
    border-radius: 10px;
    font-size: 1rem;
}

.downloadButton:hover {
    cursor: pointer;
    background: #FCA311;
    border: 2px solid #FCA311;
    color: white;
}

.downloadButton:active {
    background: #905d0a;
    border: 2px solid #905d0a;
}