@media screen and (max-width: 1504px) {
    .sidebarContainer {
        position: fixed;
        background-color: #313131;
        visibility: hidden;
        box-shadow: rgba(0, 0, 0, 0.826) 2px 5px 500px 500px;
        top: 0;
        left: 0;
        padding: 20px 40px;
        width: 50%;
        height: 100%;
        z-index: 2;

        overflow-y: scroll;
    }

    .contentContainer{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding-bottom: 40px;
    }

    .waveOverlay {
        position: relative;
        width: 100%;
        min-height: 100px;
        bottom: 600px;
        z-index: 1;
    }

    .sidebarContainer.open {
        visibility: visible;
    }

    .closeButton {
        background: none;
        border: none;
        font-size: 4rem;
        color: white;
        cursor: pointer;
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .closeButton:hover {
        color: #FCA311;
      }
      

    .description {
        text-align: center;
    }

    .description h1{
        margin: 10px 0px;
    }
    
    .description p{
        margin: 5px 0px;
    }
}

@media screen and (max-width: 875px) {
    .sidebarContainer {
        width: 75%;
    }
}
