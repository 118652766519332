.headerContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100px;
    width: auto;
    padding: 0px 20px;
    margin: 5px;
    border-radius: 20px;
    background-color: #313131;
}

.hamburgerButton {
    background: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px 40px 0px 20px;
    width: 30px;
    height: 25px;
    cursor: pointer;
}

.hamburgerButton .line {
    width: 30px;
    height: 4px;
    background-color: #fff;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.headerDescription{
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    width: auto;
    max-height: 75px;
    object-fit: contain;
}

.headerDescription div h1{
    font-size: 1.8rem;
    margin: 0px;
}

.headerDescription p{
    margin: 0px;
}

@media screen and (min-width: 1505px) {
    .headerContainer {
        display: none;
    }
} 

@media screen and (max-width: 550px) {
    .title{
        font-size: 1.2rem!important;
        margin: 0px;
    }

    .occupation{
        font-size: 1rem!important;
        margin: 0px;
    }
    
    .headerDescription p{
        margin: 0px;
    }
}

@media screen and (max-width: 425px) {
    .title {
        font-size: 1.2rem!important;
    }
    .occupation{
        font-size: .8rem!important;
    }
}

@media screen and (max-width: 320px) {
    .title {
        font-size: .9rem!important;
    }
    .occupation{
        font-size: .7rem!important;
    }

}
