.appContainer {
    display: grid;
    grid-template-columns: 25% 1fr;
    grid-template-rows: 115vh;
    gap: 10px;
}

.sidebarContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #313131;
    color: white;
    border-radius: 15px;
    height: 120vh;
    width: 100%;
}

.waveOverlay {
    position: relative;
    width: 100%;
    top: -780px;
    z-index: 1;
}

.bodyContainer{
    background-color: #313131;
    border-radius: 15px;

    overflow-y: auto;

    grid-column: 2;
    grid-row: 1;
}

.sidebarContainer, .bodyContainer{
    width: auto;
    box-sizing: border-box;
    height: auto;
    padding: 20px;
    margin: 5px;
}

/*Modal Styles*/
.moduleTitle{
    background-color: red;
}


@media screen and (max-width: 1504px) {
    .appContainer {
        grid-template-columns: 1fr;
        grid-template-rows: 100px 115vh;
        gap: 10px;
    }

    .bodyContainer{
        grid-column: 1;
        grid-row: 2;
    }

    .sidebarContainer {
        display: none;
        grid-column: 1;
        grid-row: 2;
    }

    .topPageContainer {
        visibility: visible;
    }

    
}


