.container{
    text-align: center;
    width: 100%;
    max-height: 400px;
}

.profilePicture {
    width: 100%;
    height: 200px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    object-fit: contain;
}