.container{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.logo{
    height: 80px;
    width: auto;
    object-fit: cover;
    margin-right: 5px;
}

.description h1{
    font-size: 1.5rem;
    margin: 0px;
    color: #FCA311;
}

.description p{
    margin: 5px 0px;
    font-size: 1rem;
}

