.portfolioContainer {
    height: auto;
    width: 100%;
}

.buttonsContainer{
    display: flex;
    gap: 20px;
}

.dropdownContainer {
    display: none;
}

.buttonsContainer .buttons {
    background: none;
    width: 25%;
    border: 2px solid #0077b6;
    color: #0077b6;
    padding: 15px 50px;
    border-radius: 10px;
    font-size: 1rem;
}

.buttonsContainer button:hover {
    cursor: pointer;
    background: #FCA311;
    border: 2px solid #FCA311;
    color: white;
}

.buttonsContainer button:active {
    background: #905d0a;
    border: 2px solid #905d0a;
}

.categoryDesc {
    margin-top: 20px;
}

.projectCardContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;
    max-width: 100%;
}

.card {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #515151;
    border-radius: 20px;
    padding: 20px;
    box-sizing: border-box;
}

.card img {
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    border-radius: 10px;
}

.cardDesc {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden; 
    text-overflow: ellipsis; 
    -webkit-line-clamp: 4;
    line-height: 1.5;
    max-height: calc(1.5em * 4);
}

.card h3 {
    margin: 10px 0;
}

.card p {
    margin: 10px 0;
}

.link {
    margin: 0px!important;
}

.link:hover {
    cursor: pointer;
    color: #FCA311;
}

.projectURLtext,
.projectURL {
    color: #515151;
    text-decoration: none;
    font-weight: 400;
}

.projectURL:hover {
    color: #FCA311;
}


@media screen and (max-width: 950px) {

    .buttonsContainer {
        display: none;
    }
    
    .dropdownContainer {
        display: block;
    }

    .dropdownContainer select {
        background: none;
        width: 100%;
        border: 2px solid #0077b6;
        color: #0077b6;
        padding: 15px 10px;
        border-radius: 10px;
        font-size: 1rem;
    }

    .dropdownContainer select option {
        color: black;
    }
   

}

@media screen and (max-width: 850px){
    .projectCardContainer{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px){
    .buttonsContainer{
        flex-direction: column;
    }

    .buttonsContainer .buttons{
        width: 100%;
    }

    .projectCardContainer{
        align-items: center;
        flex-direction: column;
    }
}

@media screen and (max-width: 650px){
    .projectCardContainer{
        grid-template-columns: repeat(1, 1fr);
    }
}