.socialLinks {
    display: flex;
    justify-content: center;
  }
  
  .link {
    margin: 5px;
    text-decoration: none;
    background-color: #515151;
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }
  
  .icon {
    width: 20px;
    height: 20px;
    padding: 10px;
  }

  .link:hover {
    background-color: #FCA311;
  }
  